import React from "react";
import { SearchBox } from 'react-instantsearch-dom';
import { colors } from "../colors";
import search from "../header/search.svg";
import * as styles from "./box.module.css";



function searchBox (newInputValueFn)
{
    let onChangeFn = function (e) {
        newInputValueFn(e.target.value);
    };
    
    return (
        <div
          className={ styles.searchBoxContainer }
          style={{
              borderBottom: `1px solid ${colors.green}`
          }} >

          <img
            src={search}
            alt="Search"
            style={{
                width: `17px`,
                height: `15px`,
                objectFit: `contain`,
                color: colors.green,
                margin: `0 0 3px`,
                border: 0,
                padding: 0
            }} />

          <div className={ styles.searchBox } >

            <SearchBox
              searchAsYouType={ true }
              translations={{
                  placeholder: "Search briefs"
              }}
              onChange={ onChangeFn }
              showLoadingIndicator={ true }
              />
            
          </div>
          
        </div>
    );
}

export { searchBox };
