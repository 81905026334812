import React from "react";
import algoliasearch from 'algoliasearch/lite';
import { connectHits, Configure, InstantSearch } from 'react-instantsearch-dom';
import withURLSync from "../utils/URLSync";
import { searchBox } from "./search/box";
import { newResults } from "./search/results";



const searchClient = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    process.env.ALGOLIA_API_KEY
);



class SearchLayout extends React.Component
{
    constructor (props)
    {
        super(props);
        
        this.update = this.update.bind(this);
        this.isSearchTermEmpty = this.isSearchTermEmpty.bind(this);
        this.tagClicked = this.tagClicked.bind(this);

        let initialSearchState = typeof this.props.searchState === "undefined" ? { query: "" } : this.props.searchState;
        let resultsFn = newResults(this.isSearchTermEmpty,
                                   this.tagClicked);
        this.state = {
            searchState: initialSearchState,
            resultsComponent: connectHits(resultsFn)
        };
    }

    update (newValue)
    {
        this.setState({
            searchState: { query: newValue },
        });
    }

    isSearchTermEmpty ()
    {
        if ( typeof this.state.searchState.query === "undefined" ) return true;
        return this.state.searchState.query.length === 0;
    }

    tagClicked (event) {
        let tagStr = event.target.innerText;
        this.update(tagStr);
    }
    
    render ()
    {
        const Results = this.state.resultsComponent;
        return (
            <div
              style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `center`,
                  margin: `0 0 150px`
              }} >
              
              <InstantSearch
                indexName={ this.props.searchIndex }
                searchClient={ searchClient }
                searchState={ this.state.searchState }                
                onSearchStateChange={ this.props.onSearchStateChange }
                createURL={ this.props.createURL } >

                <Configure hitsPerPage={ 1000 } />
                
                { searchBox(this.update) }

                <Results />                
                
              </InstantSearch>
              
            </div>
        );
    }
}

export default withURLSync(SearchLayout);
