import React, { useEffect } from "react";
import Layout from "../components/layout";
import SearchLayout from "../components/search_layout";
import * as styles from "./search.module.css";
import { colors } from "../components/colors";
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Search" });



const searchIndexName = process.env.ALGOLIA_BRIEF_INDEX_NAME;

const Search = ({ location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location }
          style={{
              background: colors.mediumGrey,
              backgroundColor: colors.mediumGrey,
              borderBottomColor: colors.darkerGrey
          }} >                      

          <div
            className={ styles.container } >

            <SearchLayout
              searchIndex={ searchIndexName } />
            
          </div>

        </Layout>
    );
};

export default Search;
