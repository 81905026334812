import React from "react";
import { merge } from "../../utils/functional";
import { rhythm } from "../../utils/typography";
import { Link } from "gatsby";
import { formatDate } from '../../utils/date';
import { colors } from "../colors";
import * as styles from "./headline.module.css";


export const date = (data, style = null) => {

    const ts = data.published_epoch_ts;
    const dateStr = formatDate(ts);
    const s = merge({
        fontSize: '13px',
        color: colors.visibleGrey,
        lineHeight: '1.15',
        letterSpacing: '0.33px'
    }, style);
    
    return (
        <div style={s} >
          { dateStr }
        </div>
    );
};



const tagLinkPath = (tagStr) => "/search/?query=" + tagStr;

const tagStyle = {
    marginRight: rhythm(0.5),
    fontSize: '13px',
    lineHeight: '1.38',
    letterSpacing: '0.33px',
    color: colors.orange,
    textDecoration: 'none',
    zIndex: '1'               // required b/c would appear under #headline ::before and suppress clicks
};

export const tag = (onClickFn = undefined, style = tagStyle) => {

    if ( typeof onClickFn === "function" )
        return (s) => <Link
                        key={ Math.random() }
                        to={ tagLinkPath(s) }
                        onClick={ onClickFn }
                        style={ style } >
                        { s }
                      </Link>;

    return (s) => <Link
                    key={ Math.random() }
                    to={ tagLinkPath(s) }
                    style={ style } >
                    { s }
                  </Link>;
};

export const tags = (data, onClickFn = undefined, style = null) => {
    
    const tagData = data.tags;
    const tags = tagData.visible;
    const s = merge(tagStyle, style);

    return (
        <div 
          style={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `flex-start`,
              alignItems: `flex-end`,
              flexWrap: `wrap`
          }}>
          
          { tags.map(tag(onClickFn, s)) }
          
        </div>
    );
};


export const content = (briefData) => {
    const pre = briefData['headline_pre'] ? briefData['headline_pre'] + ' ': '';
    return pre + briefData['headline'];
};

export const headline = (briefData) => {
    return (
        <div
          key={ Math.random() }
          className={ styles.headline }
          style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start"
          }} >

          <div style={{ flex: `none` }} >
            { date(briefData) }
          </div>

          <div
            style={{
                flex: `1 1 auto`,
                width: `100%`,   // IE requires this b/c flex-basis = auto
                marginTop: `6px`
            }} >
            { tags(briefData) }
          </div>

          <Link
            className={ styles.headlineLink }
            style={{
                flex: `1 1 auto`,
                width: `100%`,   // IE requires this b/c flex-basis = auto
                marginTop: `6px`,
                color: colors.black
            }}
            to={ briefData.slug_path } >
            { content(briefData) }
          </Link>
          
        </div>
    );
};

export const headlineCompact = (briefData) => {
    return (
        <div
          key={ Math.random() }
          className={ styles.headline }
          style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start"
          }} >

          <div style={{ flex: `none` }} >
            { date(briefData) }
          </div>

          <Link
            className={ styles.headlineLink }
            style={{
                flex: `1 1 auto`,
                width: `100%`,   // IE requires this b/c flex-basis = auto
                marginTop: `6px`,
                color: colors.black,
                lineHeight: `27px`
            }}
            to={ briefData.slug_path + "#headline" } >
            { content(briefData) }
          </Link>
          
        </div>
    );
};
