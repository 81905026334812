import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { colors } from '../colors';
import { date, tags } from '../brief/headline';
import * as styles from './results.module.css';



const emailTipsLink = () => {
    return (
        <OutboundLink
          style={{
              margin: '0 3px',
              color: colors.black,
              borderBottom: 'solid 1px ' + colors.lightGreen,
              textDecoration: 'none',
              boxShadow: 'none'
          }}
          href='mailto:tips@6pages.com'>
          tips@6pages.com
        </OutboundLink>
    );
};


const noResults = () => {
    return (
        <div>
          0 Briefs. Have a topic you'd like to see? Send suggestions to
          { emailTipsLink() }
        </div>
    );
};

const resultsCount = (count, maxReached) => {
    let a = maxReached ? 'All' : count;
    let b = count === 1 ? 'Brief' : 'Briefs';
    let t = count > 0 ? a + ' ' + b : noResults();
    
    return (
        <div
          style={{
              fontSize: `16px`,
              lineHeight: `1.75`,
              letterSpacing: `0.16px`,
              color: colors.black              
          }} >
          { t }
        </div>
    );
};

const cutTextByWord = (text, maxCharCount) => {
    let ss = text.split(' ');

    var acc = '';
    for (var i = 0; i < ss.length; i++)
    {
        if ( maxCharCount < acc.length + ss[i].length ) break;
        acc = acc + ' ' + ss[i];
    }
    return acc;
};


//class Summary extends React.Component
//{
//    constructor (props) {
//        super(props);
//        this.state = {
//            id: 'results-summary-' + Math.floor(Math.random() * 1000000),
//            text: this.props.text,
//        };
//        this.cutTextToSize = this.cutTextToSize.bind(this);
//    }
//
//    componentDidMount () {
//        this.cutTextToSize();
//    }
//    
//
//    cutTextToSize () {
//        let h = document.getElementById(this.state.id).clientHeight;
//        let maxHeight = this.props.numLines * 31;  // 31 = line height
//
//        if ( h > maxHeight )
//        {
//            let r = maxHeight / h;
//            let c = Math.floor(this.state.text.length * r) - 5;
//
//            this.setState({
//                text: cutTextByWord(this.props.text, c) + ' ...'
//            });
//        }
//    }
//
//    render () {
//        return (
//            <div
//              id={ this.state.id }
//              className={ `${styles.hitSummary}` }
//              style={{
//                  lineHeight: `1.82`,
//                  letterSpacing: `0.17px`,
//                  color: colors.black
//              }} >
//              { this.state.text }
//            </div>
//        );
//    }
//}

const Summary = ({ numberOfLines, textOriginal }) => {
    const [text, setText] = useState(textOriginal);
    
    const id = 'results-summary-' + Math.floor(Math.random() * 1000000);
    const lineHeight = 28;

    const cutTextToSize = () => {
        let h = document.getElementById(id).clientHeight;
        let maxHeight = numberOfLines * lineHeight;

        if ( h > maxHeight )
        {
            const r = maxHeight / h;
            const c = Math.floor(text.length * r) - 5;
            setText(cutTextByWord(text, c) + ' ...');
        }
    };
    useEffect(cutTextToSize, [id, numberOfLines, text]);
    
    return (
        <div
          id={ id }
          className={ `${styles.hitSummary}` }
          style={{
                lineHeight: String(lineHeight) + `px`,
                letterSpacing: `0.17px`,
                color: colors.black
            }} >
          { text }
        </div>
    );
};


const onMobile = () => {
    if (typeof window === `undefined`) return false;
    return window.innerWidth <= 1032;
};


const renderHit = (tagClickedFn, numberOfLines) => {
    return function (hit)
    {
        var briefData = hit;
        briefData.fields = { path: '/' };
        briefData.tags = { visible: briefData.tags_visible };

        var url = briefData.slug_path;
        url = onMobile() ? url + '#headline' : url;
        
        return (
            <div
              key={ Math.random() }
              className={ styles.hitContainer }
              style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `flex-start`,
              }} >
              
              <div
                className={ styles.hitFields }
                style={{
                    display: `flex`,
                    justifyContent: `flex-start`,
                }} >

                { date(briefData) }
                
                <div
                  className={ styles.hitTags } >
                  { tags(briefData, tagClickedFn) }
                </div>
                
              </div>

              
              <Link
                to={ url }
                className={ `${styles.hitHeadline} ${styles.link}` }
                style={{
                    fontFamily: `"Overpass", sans-serif`,
                    fontSize: `24px`,
                    fontWeight: `bold`,
                    lineHeight: `1.25`,
                    color: colors.black
                }} >
                { briefData.headline }
              </Link>

              <Summary
                numberOfLines={ numberOfLines }
                textOriginal={ briefData.summary } />
              
            </div>
        );
    };
};



const numberOfLines = (defaultNumLines = 2) => {
    let n = defaultNumLines;
    if ( typeof window !== 'undefined' )
        n = window.innerWidth > 1280 ? 2 : 5;
    return n;
};


class ResultsContainer extends React.Component
{
    constructor (props) {
        super(props);
        this.state = {
            numberOfLines: 2
        };
        this.updateNumLines = this.updateNumLines.bind(this);
    }

    componentDidMount () {
        this.updateNumLines();
        if ( typeof window !== 'undefined' )
            window.addEventListener('resize', this.updateNumLines);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.updateNumLines);
    }
    
    updateNumLines () {
        if ( typeof window !== 'undefined' )
            this.setState({
                numberOfLines: numberOfLines(this.state.numberOfLines)
            });
    }
    

    render ()
    {
        let f = renderHit(this.props.tagClickedFn, this.state.numberOfLines);
        return (
            <div
              className={ styles.hitsContainer } >
              
              <div
                style={{
                    width: `100%`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `flex-start`
                }} >
                
                { this.props.hits.map(f) }
                
              </div>
              
            </div>
        );
    }
}




// maxHit

const newMaxHit = (count = 0, found = false) => {
    return {
        count: count,
        found: found
    };
};

const updateMaxHit = (maxHit, isSearchTermEmptyFn, hits) => {
    if ( ! maxHit.found )
    {
        if (hits.length > maxHit.count)
            maxHit.count = hits.length;

        if (hits.length > 0 && isSearchTermEmptyFn())
            maxHit.found = true;
    }
    return maxHit;
};

const maxHitReached = (maxHit, hits) => {
    return maxHit.found && hits.length >= maxHit.count;
};




// renderHits <Results /> component

export const newResults = (isSearchTermEmptyFn, tagClickedFn) => {
    var maxHit = newMaxHit();
    
    return ({ hits }) => {
        maxHit = updateMaxHit(maxHit, isSearchTermEmptyFn, hits);
        return (
            <div
              className={ styles.resultsContainer }
              style= {{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `stretch`
              }} >
              { resultsCount(hits.length, maxHitReached(maxHit, hits)) }

              <ResultsContainer
                hits={ hits }
                tagClickedFn={ tagClickedFn } />
            </div>
        );
    };
};
